<template>
	
					<br>
					<div class="row ">
					
						<div class="col-sm-3 p-3  text-white text-center " id="testcard"  style="background-color: #7f7f27;">
					
							<div class="d-flex justify-content-between ">
								<div>
									加减滑杆
								</div>
								<div>
									<button type="button" class="btn btn-sm btn-sm-square btn-outline-dark  mb-2 "
										data-bs-toggle="modal" data-bs-target="#myModalcardedit" id="cardeditbtn_00"
										@click="mainbox($event)">
										<i class="fa fa-cog " id="cardeditico_00"></i>
									</button>
								</div>
							</div>
							
							<div class="d-flex justify-content-between ">
								
									<v-btn density="compact" icon="mdi-minus"></v-btn>
									
									<v-slider style="width: 70%;" thumb-label="always" thumb-color="#00ff00" 
									min="-5" max="255" step="1" ></v-slider>
									
									<v-btn density="compact" icon="mdi-plus"  ></v-btn>
									
									<div class="ms-2" style="width: 15%;">
										{{name}}
									</div>
								
							</div>

						</div>
					
					</div>

</template>

<script>
export default {
  name: "CardSlider",
	
  props: {
    dictKey: String, // 字典code
    updataDict: String, // 回显绑定的值
    value: String
  },
  data() {
    return {
      dictionary: "", // 绑定选中的值
      dictionaryOptions: [], // 拉下字典选项
			name:"Ligh",
    };
  },
  watch: {
    dictKey: {
      immediate: true,
      handler() {
        this.getSystem();
      }
    },
    value: {
      handler(val) {
        this.dictionary = val;
      },
      deep: true
    }
  },
  created() {
    this.getSystem();
  },
  mounted() {
    this.dictionary = this.value;
		
		alert("CardSlider");
  },
  methods: {
    // 获取数据字典
    async getSystem() {
      const { code, data } = await getDictList(this.dictKey);
      if (code != "200") return;
      this.dictionaryOptions = data;
    },
    selectChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    }
  },
	
}
	
</script>

<style>
</style>