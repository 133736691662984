/*
 * @Author: 
 * @Date: 
 * @Last Modified by: 
 * @Last Modified time: 
 */

// 导出中心
/*
export function download(fileId) {
  return request({
    url: `/file/download/${fileId}`,
    responseType: 'blob',
    method: 'GET',
  })
}

export default { fileList, fileAdd, fileDel, fileUpdate, fileDetail }
*/


	/*************************************************************************************
	 *
	 *
	 *
	 *
	 *************************************************************************************/
	export function	isJson(obj) {
		let res;
		try {
			res = JSON.parse(obj);
	
		} catch (e) {
		
			return false;
		}
		return Object.prototype.toString.call(res) === '[object Object]';
	};
	/*************************************************************************************
	 *
	 *
	 *
	 *
	 *************************************************************************************/
	export	function	isJsonArray(obj) {
		let res;
		try {
			res = JSON.parse(obj);
		} catch (e) {
			return false;
		}
		//alert(Object.prototype.toString.call(res));
		return Object.prototype.toString.call(res) === '[object Array]';
	};

	/*************************************************************************************
	 *
	 *判断是不是JSON
	 *
	 *
	 *************************************************************************************/
	export function 	isJsonTrue(obj, where) {

		let res;
		try {
			res = JSON.parse(obj);
		} catch (e) {

			alert(where + "输入框不是JSON");
			return false;

		}
		return Object.prototype.toString.call(res) === '[object Object]';
	};
	/*************************************************************************************
	 *
	 *判断是不是JSON嵌套JSON数组
	 *
	 *
	 *************************************************************************************/
	export function	isJsonAndArrayTrue(obj, keybuf, where) {

		//alert(keybuf.length);

		if (!isJson(obj)) {
			alert(where + "输入框不是JSON,需要JSON嵌套");
			return false;
		} else {
			let resjson = JSON.parse(obj);

			for (let i = 0; i < keybuf.length; i++) {
				if (typeof(resjson[keybuf[i]]) === "undefined") {
					alert(where + "输入框不存在" + keybuf[i]);
					return false;
				} else {
					if (!isJsonArray(JSON.stringify(resjson[keybuf[i]]))) {
						alert(where + "输入框的键" + keybuf[i] + "不是JSON数组");
						return false;
					}
				}
			}

		}

		return true;
	};
	
	
	
	
	export default { isJson, isJsonArray, isJsonTrue, isJsonAndArrayTrue }