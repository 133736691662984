import { createStore } from 'vuex'


export default createStore({
  state: {
		/*
		hostip:'http://localhost:80',
		register:'/register',
		apploginform:'/apploginform',
		getproduct:'/getproduct',
		createproduct:'/createproduct',
		deleteproduct:'/deleteproduct',
		uploadFile:'/uploadFile',
		getdevicestatus:'/getdevicestatus',
		getdevicestatu:'/getdevicestatu',
		deletedevicestatus:'/deletedevicestatus',
		deletedevicestatuss:'/deletedevicestatuss',
		getdevicebind:'/getdevicebind',
		deletedevicebind:'/deletedevicebind',
		sendmqtt:'/sendmqtt',
		getdevice:'/getdevice',
		createdevice:'/createdevice',
		deletedevice:'/deletedevice',
		sendmqtt:'/sendmqtt',
		sent_prd:'/sent_prd',
		sent_dev:'/sent_dev',
		sent_bind:'/sent_bind',
		*/
	 
	//注意  本地访问时 
	//当本地运行用于IDEA相同的端口时，先运行IDEA 再运行前端,前端会自适应端口
	//部署需要改为服务器IP 106.14.178.104
	//手机访问需要把localhost修改为IP 
	//端口为IDEA的端口			  								http://192.168.216.112:80
	hostip:'http://119.23.45.211:80', 
	
	
	apploginform:'/apploginform',
	getproduct:'/getproduct',
	createproduct:'/createproduct',
	deleteproduct:'/deleteproduct',

	getdevicestatus:'/getdevicestatus',
	getdevicestatu:'/getdevicestatu',
	deletedevicestatus:'/deletedevicestatus',
	deletedevicestatuss:'/deletedevicestatuss',
	
	getdevicebind:'/getdevicebind',
	deletedevicebind:'/deletedevicebind',
	

	
	getdevice:'/getdevice',
	createdevice:'/createdevice',
	deletedevice:'/deletedevice',
	
	sendmqtt:'/sendmqtt',
	sent_prd:'/sent_prd',
	sent_dev:'/sent_dev',
	sent_bind:'/sent_bind',
	
	
	
	//------------------------------------------------------------------------tuser
	tuser_register:			'/tuser/register',
	tuser_getpasswsalt:	'/tuser/getsalt',
	
	tuser_gettoken:	'/tuser/gettoken',
	tuser_logout:		'/tuser/logout',

	tuser_updatetoken:	'/tuser/updatetoken',
	tuser_login:				'/tuser/login',
	
	tuser_updatepass:	'/tuser/updatepass',

	//------------------------------------------------------------------------tprd
	tprd_getprdtbmd:			'/ttablemodulePrd/getprdtbmd',
	tprd_setprdtbmd:			'/ttablemodulePrd/setprdtbmd',
	
	tprd_getprd:					'/twebprdProduct/getprd',
	tprd_getprdbytime:		'/twebprdProduct/getprdbytime',
	tprd_setprd:					'/twebprdProduct/setprd',
	tprd_createprd:				'/twebprdProduct/createprd',
	tprd_deleteprd:				'/twebprdProduct/deleteprd',
	
	tprd_deleteprdota:				'/twebprdProduct/deleteprdota',
		
	//------------------------------------------------------------------------OTA 文件	
	uploadFile:				'/FileUpDown/uploadFile',
	ota_getotaurl:		'/FileUpDown/getotaurl',
	
	//------------------------------------------------------------------------tdevbasic
	tdev_getdevtbmd:				'/ttablemoduleDev/getdevtbmd',
	tdev_setdevtbmd:				'/ttablemoduleDev/setdevtbmd',
	
	
	tdev_getdevbasic:				'/twebdevBasic/getdev',
	tdev_getdevbasicbytime:	'/twebdevBasic/getdevbytime',
	tdev_setdevbasic:				'/twebdevBasic/setdev',
	tdev_createdevbasic:		'/twebdevBasic/createdev',
	tdev_deletedevbasic:		'/twebdevBasic/deletedev',
	
	
	
	tdev_sentdevbasicdata:'/twebdevBasic/sentiotdata',
	tdev_sentdevbasicdata_nosave:'/twebdevBasic/sentiotdatanosave',
	
	//------------------------------------------------------------------------tdevmq
	tdev_getdevmq:'/twebdevMq/getdev',
	tdev_getdevmqbytime:'/twebdevMq/getdevbytime',
	tdev_setdevmq:'/twebdevMq/setdev',
	tdev_createdevmq:'/twebdevMq/createdev',
	tdev_deletedevmq:'/twebdevMq/deletedev',
	
	tdev_sentdevmqdata:'/twebdevMq/sentiotdata',
	
	//------------------------------------------------------------------------tdevnb	
	tdev_getdevnb:				'/twebdevNb/getdev',
	tdev_getdevnbbytime:	'/twebdevNb/getdevbytime',
	tdev_setdevnb:				'/twebdevNb/setdev',
	tdev_createdevnb:			'/twebdevNb/createdev',
	tdev_deletedevnb:			'/twebdevNb/deletedev',		
	
	tdev_sentdevnbdata:		'/twebdevNb/sentiotdata',
	
	//------------------------------------------------------------------------tdevbind
	tdevbind_getdevbindtbmd:	'/ttablemoduleBind/getdevbindtbmd',
	tdevbind_setdevbindtbmd:	'/ttablemoduleBind/setdevbindtbmd',
	
	
	tdevbind_getdevbind:			'/twebiotdevBind/getdevbind',
	tdevbind_getdevbindbytime:'/twebiotdevBind/getdevbindbytime',
	tdevbind_setdevbind:			'/twebiotdevBind/setdevbind',
	
	tdevbind_createdevbind:		'/twebiotdevBind/createdevbind',
	tdevbind_deletedevbind:		'/twebiotdevBind/deletedevbind',
	
	
	
	tdevbind_sentdevbinddata:'/twebiotdevBind/sentiotdata',
	tdevbind_sentdevbinddata_nosave:'/twebiotdevBind/sentiotdatanosave',
	//------------------------------------------------------------------------tdevstatus
	tdevstatus_getdevstatustbmd:	'/ttablemoduleStatus/getdevstatustbmd',
	tdevstatus_setdevstatustbmd:	'/ttablemoduleStatus/setdevstatustbmd',
	
	
	
	tdevstatus_getdevstatus:			'/twebiotdevStatus/getdevstatus',
	tdevstatus_getdevstatusbyflag:			'/twebiotdevStatus/getdevstatusbyflag',
	tdevstatus_getdevstatusbytime:'/twebiotdevStatus/getdevstatusbytime',
	tdevstatus_setdevstatus:			'/twebiotdevStatus/setdevstatus',
	
	tdevstatus_delete_devstatusbytime:'/twebiotdevStatus/deletedevstatusbytime',
	
	tdevstatus_createdevstatus:		'/twebiotdevStatus/createdevstatus',
	tdevstatus_deletedevstatus:		'/twebiotdevStatus/deletedevstatus',
	
	
	
	//------------------------------------------------------------------------uimodule	
	t_getdevuimdbydevid:						'/tuimoduleAll/getdevuimdbydevid',			
	t_getdevbinduimdbybindid:				'/tuimoduleAll/getdevbinduimdbybindid',			
	
	t_setdevuimdbydevid:						'/tuimoduleAll/setdevuimdbydevid',			
	t_setdevbinduimdbybindid:				'/tuimoduleAll/setdevbinduimdbybindid',		
	

			
			
			
			
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {

  }
})


