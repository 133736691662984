<template>
	
	

	
	<div class="  d-flex  mt-10 p-0">


	        <!-- Recent Sales Start -->
	        <div class=" container-fluid  mt-10 p-0">
						
	            <div class="  sgdbg-secondary  xtext-center xrounded p-4 ">
									
									<div id="bieming" class="collapse text-start text-warning mb-4 ">	
										
										
										
										
										<div class="table-responsive " id="tball">
												<div class="flex-container ">   
													 
													<div class="  flex-item d-flex align-items-center" > 
														<label for="" class="me-2">{{"全写"}}:</label> 
														<input class=" bg-light  "   name="remember" id="tball_inp"> 
													</div>
													
													<div class="  flex-item  d-flex align-items-center ms-0" >
														<button class="btn btn-sm btn-success " @click="add_keyall($event)">全写</button>
														<label for=""></label>
													</div>
												</div>
										</div>
										
										<br />
										<div class="table-responsive " id="">
											<div class="flex-container ">  
											 <json-viewer
											 class="text-start w-50  "
											 style="background-color: #d5d5cf;"
											 :value="jsonData"
											 :expand-depth='5' 
											 copyable
											 boxed
											 ></json-viewer> 
											 <br />
											 
											 <textarea  class="w-50 text-white border-danger" rows="10"
											 :value="JSON.stringify(show_table_module)"
											 placeholder="填入JSON, 验证JSON格式, 本文本域失去焦点时解析"
											 id="md1_textarea" @blur.native.capture="ck_md_textarea($event)" ></textarea>
											 
											</div>
										</div>
										<br />
										
										
										
										<div class="table-responsive " id="">
												<div class="flex-container d-flex justify-content-between">   
													 
													<div class="  flex-item  d-flex align-items-center " >
														<button class="btn btn-sm btn-success " @click="add_key($event)">增加</button>
														<label for=""></label>
													</div>
													
													<div class="  flex-item  d-flex align-items-center " >
														<button class="btn btn-sm btn-danger " @click="del_key($event)">删除</button>
														<label for=""></label>
													</div>
													
													<div class="  flex-item  d-flex align-items-center " >
														<button class="btn btn-sm btn-danger " @click="reset_module($event)">重置</button>
														<label for=""></label>
													</div>
													
													<div class="  flex-item  d-flex align-items-center " >
														<button class="btn btn-sm btn-info " @click="save_module($event)">保存</button>
														<label for=""></label>
													</div>
													
													
													
												</div>
										</div>
										
										
										
										<div class="table-responsive " id="tbname">
												<div class="flex-container ">   
													 
													<div class="  flex-item d-flex align-items-center" > 
														<label for="" class="me-2">{{"名称"}}:</label> 
														<input class=" bg-light  "  :value="show_table_module.tbmdname[0].name" name="remember"> 
													</div>
										
												</div>
										</div>
										
										<div class="table-responsive " >
											
												<div class="flex-container " id="tbkey">   
													 
													 <div class="  flex-item  d-flex align-items-center " >
													 	<label for="">键名:</label>
													 </div>
													 
													<div class="  flex-item d-flex align-items-center " v-for="(item,index) in show_table_module.key"> 
														<!--<label for="" class="me-2">{{index}}:</label> -->
														<input class=" bg-light  " :value="show_table_module.key[index].K" name="remember"> 
													</div>
													
												</div>
												
												<div class="flex-container " id="tbmean">
													 
													 <div class="  flex-item  d-flex align-items-center " >
													 	{{"含义"}}:
													 </div>
													 
													<div class="  flex-item d-flex align-items-center" v-for="(item,index) in show_table_module.key"> 
														<!--<label for="" class="me-2">{{show_table_module.key[index].K}}:</label> -->
														<input 	class="bg-light"  v-if="index==(show_table_module.key.length-1)"
														:value="show_table_module.name[0][show_table_module.key[index].K]" name="remember"> 
														<input v-else class=" bg-light  " :value="show_table_module.name[0][show_table_module.key[index].K]" name="remember"> 
													</div>
													
												</div>
										</div>
										
										
										
									</div>								
							
							
						
						
								
						<!--
						<h6 class=" text-center ">{{table_module}}</h6>-->
						<h4 class="mb-0 pagination justify-content-center">【我的产品】</h4> 
						<!--
						<h6 class=" text-center ">{{show_table_module}}</h6>
						
						<h6 class=" text-center ">{{show_data_module.val[0]}}</h6>-->
						
						<br /><br />
						
						
	          <div class="row">
						
							<div class="col-sm-6 ">
								<input type="button" :value="'MAX:  '+max_nums" class="btn btn-sm btn-outline-light text-white me-2" id="h_totalnums">
							</div>
						
						
							<div class="col-sm-6 d-flex justify-content-end">
								
								<div class="nav-item dropdown ">
									<a href="" class="nav-link dropdown-toggle text-warning " data-bs-toggle="dropdown">
											<i class="fa fa-caret-square-o-down me-lg-2 text-warning"></i>
											<span class="d-none d-lg-inline-flex text-warning">查询</span>
									</a>
									
									<div class="dropdown-menu xdropdown-menu-end  bg-body  dropdown-menu-scroll"  >
										
										<div class=" text-info">
											起始	<input type="datetime-local" name="bdaytime" class="bg-light  " v-model="query_date_s">
										</div>
										<div class=" text-info">
											结束 <input type="datetime-local" name="bdaytime" class="bg-light  " v-model="query_date_e">
										</div>
										<div class="text-white  xtext-center">
											<input type="button" value="查询" class="btn btn-sm btn-info  " @click="date_query($event)">
										</div>
										
									</div>
									
								</div>
								
								
								<div class="nav-item dropdown ">
									<a href="" class="nav-link dropdown-toggle text-warning " data-bs-toggle="dropdown">
											<i class="fa fa-caret-square-o-down me-lg-2 text-warning"></i>
											<span class="d-none d-lg-inline-flex text-warning">筛选</span>
									</a>
									
									<div class="dropdown-menu xdropdown-menu-end  bg-body  dropdown-menu-scroll text-center"  >
										
										<div class="dropdown-item d-flex align-items-center" >
											<input class=" btn-outline-light text-warning" type="checkbox"
											@change="filter_click"
											v-model="fileter_all"
											:id="'filter_inp_all'">
											
											<label  class="text-warning dropdown-item" :id="'filter_lab'">
												全部
											</label>
											
										</div>
										
										<div class="dropdown-item d-flex align-items-center" v-for="(item,index) in show_table_module.key">
											<input class=" btn-outline-light text-warning" type="checkbox"
											@change="filter_click"
											 v-model="fileter_buf[index]"
											:id="'filter_inp'+index">
											
											<label  class="text-warning dropdown-item" :id="'filter_lab'+index">
												{{show_table_module.name[0][show_table_module.key[index].K]}}
											</label>
											{{fileter_buf[index]}}
										</div>
										
										
									</div>
									
								</div>
								
								
								<div class="nav-item dropdown">
									<a href="" class="nav-link dropdown-toggle text-info" data-bs-toggle="dropdown">
											<i class="fa fa-caret-square-o-down me-lg-2 text-info"></i>
											<span class="d-none d-lg-inline-flex text-info">操作</span>
									</a>
									<div class="dropdown-menu xdropdown-menu-end  bg-body  dropdown-menu-scroll text-center" >
										
										<button type="button" class="btn  text-info dropdown-item" data-bs-toggle="collapse" data-bs-target="#bieming">修改表格模型</button>
									
										<hr class="dropdown-divider">
										<button type="button" class="btn  text-info dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal1">创建产品</button>
										
										<hr class="dropdown-divider">
										<button type="button" class="btn btn-primary text-danger dropdown-item " id="del_selected"
										@click="del_selected($event)"
										>删除选中</button>
										
									</div>
								</div>
								
								
							</div>
						
						</div>
						<br />
						
						
					
						<div class="table-responsive   xoverflow-scroll " >
							
								<table class="table text-start  text-nowrap table-hover align-middle table-bordered table-sm table-responsive-sm mb-0">
									 
										<thead >
												<tr class="text-white">
														<th scope="col"><input class="form-check-input" type="checkbox" 
														v-model="del_select_all"
														@change="del_select_click($event,0)"
														:id="'th_tr_inpall'"></th>
														
														<th scope="col"  >
														<label class="">序号</label>
														</th>
														
														<th scope="col" v-for="(item,index) in show_table_module.key" >
														{{show_table_module.name[0][show_table_module.key[index].K]}}
														<label class="text-success">【{{show_table_module.key[index].K}}】</label>
														</th>
												</tr>
										</thead>
				
										<tbody id="tbody1"  @click="tbodyclick($event)">					 
											
											<tr 
											v-for="(item,index1) in show_data_module.val"
											:class="setClasstrselect(del_select_buf[index1])"
											:id="'tbody_tr_'+index1"
											>
												
													<td><input class="form-check-input" type="checkbox" 
													v-model="del_select_buf[index1]"
													@change="del_select_click($event,0)"
													:id="'tbody_tr_inp'+index1"></td>
													
													<td   > 
														<label >
														{{index1+1}}
														</label>
													</td>
													
													<td v-for="(item,index) in show_table_module.key" :id="'tbody_tr_'+index1+'_td'+index " > 
													
														<div v-if="show_table_module.key[index].K=='otahttpuri'">
															<i class="fa fa-check btn btn-sm btn-success mx-2" v-if="show_data_module.val[index1][show_table_module.key[index].K]"></i> 
															<i class="fa fa-check btn btn-sm btn-light mx-2" 	v-else></i> 
														</div>
														
														<div v-else-if="show_table_module.key[index].K=='ACT'">
															<div class="nav-item dropdown " v-if="show_data_module.val[index1][show_table_module.key[index].K]">
																<a href="" class="nav-link dropdown-toggle text-info" data-bs-toggle="dropdown">
																		<i class="fa fa-caret-square-o-down me-lg-2 text-info"></i>
																		<span class="d-none d-lg-inline-flex text-info">操作</span>
																</a>
																<div class="dropdown-menu  dropdown-menu-scroll bg-body text-center " style="z-index: 1000;" >
																	
																	<router-link to="" class="dropdown-item text-warning" 
																	:id="'tbody_tr_detail_'+index1"
																	@click="detail_click($event)"
																	data-bs-toggle="modal" data-bs-target="#myModal3" >详情</router-link>
																	
																	
																	<hr class="dropdown-divider">
																	<router-link to="" class="dropdown-item text-success"
																	:id="'tbody_tr_devxxx_'+index1"
																	@click="dev_click($event)"
																	>  设备</router-link>
																	
																	<hr class="dropdown-divider">
																	<router-link to="" class="dropdown-item text-warning"
																	:id="'tbody_tr_otaxxx_'+index1"
																	@click="ota_click($event)"
																	>OTA</router-link>	
																	
																	<!--
																	<hr class="dropdown-divider">
																	<router-link to="" class="dropdown-item text-warning"
																	:id="'tbody_tr_sentxx_'+index1"
																	@click="sent_click($event)"
																	>发送</router-link>
																	-->
																	
																	<hr class="dropdown-divider">
																	<router-link to="" class="dropdown-item text-danger"
																	:id="'tbody_tr_delxxx_'+index1"
																	@click="del_click($event)"
																	>删除</router-link>
																	
																</div>
															</div>
														</div>
														
														<label v-else >
														
														{{show_data_module.val[index1][show_table_module.key[index].K]}}
														<!-- {{show_table_module.key[index].K}} -->
														
														</label>
														
													</td>
													
											</tr>
											
										</tbody>
										
								</table>
								
								
							<v-spacer class="mt-10 mb-10"></v-spacer>	
							<br />
							<br />
							<br />
							<div class="d-flex justify-content-center sgdbg-secondary rounded  mt-10 mb-5 float-start " >
																 
								 <select class="btn btn-sm btn-outline-warning me-2"  id="select_nums" value="20" 
								 v-model="show_nums"
								 @change="chage_tb_show_nums($event)" >
								 <option :value="5">5</option>
										<option :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="200">200</option>
								 </select>
								 
								 <button class="btn btn-sm btn-outline-primary me-2" @click="page_pages_del()" ><i class="fa fa-angle-left "></i></button>
								 <div class="btn-group me-2" role="group">
									 
									<div v-for="n in page_max" id="page_inp">
										<input type="radio" class="btn-check" name="btnradio" 
										@click="page_pages_click($event)"
										:id="'btnradio_'+(n-1)" autocomplete="off" :checked="page_checked[n-1]">
										<label class="btn btn-outline-primary" :for="'btnradio_'+(n-1)"> {{page_nums[n-1]}}</label>
									</div>	
									
								 </div>
								 <button class="btn btn-sm btn-outline-primary" @click="page_pages_add()"><i class="fa fa-angle-right "></i></button>
								 
								 
							</div>	
								
							<div class="d-flex justify-content-center sgdbg-secondary rounded  mt-10 mx-5 mb-5 float-start " >
								<label for="" class="btn btn-sm btn-outline-secondary text-white mt-1">前往</label>
								<input min="1" class="bg-body text-warning w-25 mt-1 text-center" type="number" placeholder="N" id="goto_page_inp">
								<label for="" class="btn btn-sm btn-outline-secondary text-white mt-1">页</label>
								<button class="btn btn-sm btn-outline-primary mx-2 mt-1" @click="goto_page($event,0)"><i class="fa fa-rocket"></i></button>
								{{page_pages}}+{{page_nums_i}}
							</div>	
							
							
						
						</div>
									

					 
						
			
								
								
					
					
					
					
					

					
					
						
					
					<!-- 模态框 1 -->
					<div class="modal fade" id="myModal1">
					  <div class="modal-dialog modal-lg">
					    <div class="modal-content">
					
					      <!-- 模态框头部 -->
					      <div class="modal-header">
					        <a class="btn btn-sm btn-info">创建产品， 每个产品只能有一个OTA升级文件</a>
					        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
							
					      </div>
					
					      <!-- 模态框内容 -->
					      <div class="modal-body">
							 
									<div class="pagination justify-content-between mb-2">
										<a class="btn btn-sm btn-danger">协议</a>
										 <select class="btn btn-sm btn-outline-info text-start "  name="way" id="pdt_way" value="MQTT"  >
											 <option value="MQTT">MQTT</option>
											 <option value="MQ">MQ</option>
											 <option value="COAP">COAP</option>
											 <option value="LwM2M">LwM2M</option>
											 <option value="HTTP">HTTP</option>
											 <option value="TCPIP">TCPIP</option>
											 <option value="TCPIP">UDP</option>
										 </select>
									</div>
										
									<div class="pagination justify-content-between mb-2">
										 <a class="btn btn-sm btn-danger">类型</a>
										 <select class="btn btn-sm btn-outline-info text-start" name="type" id="pdt_type" value="AQ"  >
											 <option value="AQ">AQ</option>
											 <option value="BQ">BQ</option>
											 <option value="CQ">CQ</option>
											 <option value="DQ">DQ</option>
										 </select>
									</div>  
										 
									<div class="pagination justify-content-between mb-2">
										 <a class="btn btn-sm btn-danger">产品名称</a>
										 <input class="border-danger" type="text" name="productname" id="pdt_name" placeholder="必填" >
									</div>
									
									
							
							
							  </div>
					
					      <!-- 模态框底部 -->
					      <div class="modal-footer">
									<button type="button" class="btn btn-info" data-bs-dismiss="modal"  id="mdok_1"
									@click="create_product($event)">创建</button>
					        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" >关闭</button>
					      </div>
					
					    </div>
					  </div>
					</div>
					
					
					
					
					<!-- 模态框2 -->
					<div class="modal fade" id="myModal2">
					  <div class="modal-dialog modal-lg">
					    <div class="modal-content">
					
					      <!-- 模态框头部 -->
					      <div class="modal-header">
					        <a class="btn btn-sm btn-info">删除产品</a>
					        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
							
					      </div>
					
					      <!-- 模态框内容 -->
					      <div class="modal-body">
							 
							 
							   <a class="btn btn-sm btn-danger">
							   只能删除没有设备的产品
							    </a>
									
									
							 
							
						  </div>
					
					      <!-- 模态框底部 -->
					      <div class="modal-footer">
							
							<button type="button" class="btn btn-danger" data-bs-dismiss="modal"  @click="delet_product">删除</button>
							
					        <button type="button" class="btn btn-info" data-bs-dismiss="modal" >关闭</button>
							
					      </div>
					
					    </div>
					  </div>
					</div>
					
					
					
					
					<!-- 模态框3 -->
					<div class="modal fade " id="myModal3">
					  <div class="modal-dialog modal-lg">
					    <div class="modal-content sgdbg-secondary text-white">
					
					      <!-- 模态框头部 -->
					      <div class="modal-header">
					        <a class="btn btn-sm btn-info">产品详情</a>
					        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
							
					      </div>
								
					      <!-- 模态框内容 Start-->
					      <div class="modal-body ">
									
									
							 
							   <div class="d-flex mb-3" v-for="(item,index) in show_table_module.key">
									 
									<div class="  flex-fill sgdflex30 " >
										<div class="table-responsive">
										<div class="flex-container"> 			 
											{{show_table_module.name[0][show_table_module.key[index].K]}}:								 
										</div>
										</div>						 
									</div>				
							    <div class="flex-fill sgdflex70 ms-2" >
										 <div class="table-responsive">
										 <div class="flex-container "> 
											{{show_data_module.val[detailid][show_table_module.key[index].K]}}				 
										 </div>
										 </div>	
									</div>	
														 
							   </div>
								 
								 
							 
								</div>
								<!-- 模态框内容 End-->
					
					      <!-- 模态框底部 -->
					      <div class="modal-footer">
									
					        <button type="button" class="btn btn-info" data-bs-dismiss="modal" >关闭</button>
							
					      </div>
					
					    </div>
					  </div>
				</div>
					
					
					

					
	            </div>
	        </div>
	        <!-- Recent Sales End -->

	</div>

	

	
</template>


<script>
	

	
//*************************************************************************************【组件引入】	
import axios from 'axios'
import $ from 'jquery';

import * as Math from 'mathjs'

import { inject,onMounted, onUnmounted,root } from 'vue';

import { isJson, isJsonArray, isJsonTrue, isJsonAndArrayTrue } from "../assets/js/myjson.js";
import { partition, quickSortRecursive, quickSortIterative } from "../assets/js/sort.js";

//*************************************************************************************【JS全局变量】	

	let url_tprd_getprdtbmd="";
	let url_tprd_setprdtbmd="";
	let url_tprd_getprd="";
	let url_tprd_getprdbytime="";
	let url_tprd_setprd="";
	let url_tprd_createprd="";
	let url_tprd_deleteprd="";
	let url_tprd_deleteprdota="";

	let username;
	let usertoken;
	let idArray;

	let pagenums	;//每页显示的条数
	//let totalnums	;//最大查询总条数 page_max*200
	let stringjson;	 //axios返回response的data   为json格式
	
	let arr = new Array(200);
	
//*************************************************************************************【组件相关】
//*************************************************************************************【组件相关】
//*************************************************************************************【组件相关】
//*************************************************************************************【组件相关】
//*************************************************************************************【组件相关】
	export default {
	  name: 'product',
		
	  data () {
	    return {
				max_nums:0,
				table_module:null,		//JSON 	保存在数据库的字符串转化出来的JSON
				show_table_module:null,			//JSON		界面显示渲染时用到的显示模型JSON
				data_module:null,			//JSON		后台传过来的查询结果的JSON
				show_data_module:null,//JSON 界面显示渲染时用到的数据模型JSON
				create_data_module:null,//JSON创建产品变量
				fileter_buf:[],				//筛选的选择，按照key的BUF对应
				fileter_all:false,		//筛选的全选
				del_select_buf:[],		//删除的选择，按照key的BUF对应
				del_select_all:false,	//删除的全选
				detailid:0,						//详情的ID
				jsonData:{k:"v"},				//JSONview的缓存
				query_date_s:null,		//时间查询的起始时间
				query_date_e:null,		//时间查询的结束时间
				
				show_nums:5,					//每页的显示数量
				show_index_s:0,				//显示的下标（相当于data_module的显示部分，起始下标开始，显示show_nums条数据）
				
				page_max:5,						//翻页标签数 必须是大于2的奇数
				page_pages:3,				  //翻页标签选中页号
				page_nums:[],					//所有翻页页号存储
				page_nums_i:0,				//所有翻页页号存储的下标
				page_checked:[],			//翻页标签中选中的页为TRUE 否则为false
				
				time_selected:false,
									
				mydata:{
				 magss: 'product1',
				 maxnums:'5'	
				},
	    }
	  },
		
  setup() {
			
    /*onMounted(() => {});*/
		
  },
  components: {},
	beforeCreate(){

	},
	created() {
		
	 //srore定义的值
	 url_tprd_getprdtbmd	=this.$store.state.hostip+this.$store.state.tprd_getprdtbmd; 
	 url_tprd_setprdtbmd	=this.$store.state.hostip+this.$store.state.tprd_setprdtbmd; 
	 
	 url_tprd_getprd			=this.$store.state.hostip+this.$store.state.tprd_getprd;
	 url_tprd_getprdbytime=this.$store.state.hostip+this.$store.state.tprd_getprdbytime;
	 url_tprd_setprd			=this.$store.state.hostip+this.$store.state.tprd_setprd;
	 url_tprd_createprd	=this.$store.state.hostip+this.$store.state.tprd_createprd;
	 url_tprd_deleteprd	=this.$store.state.hostip+this.$store.state.tprd_deleteprd; 
	 url_tprd_deleteprdota	=this.$store.state.hostip+this.$store.state.tprd_deleteprdota; 
	 
	 //上个页面传来的值
	 username	=this.$route.params.username;
	 usertoken	=this.$route.params.usertoken;		
	 
	 
		
		/*
		console.log("A");
		//先拿表格模型  里面拿数据
		const ret1=this.axios_get_prdtbmd();
		console.log("ret1",ret1);
		console.log("B");

		//再拿表格数据
		const ret2=this.axios_get_prd();
		console.log("ret2",ret2);		
		console.log("C");
		
		
		const ret11=this.axios_get_prdtbmd();
		console.log("ret11",ret11);
		console.log("D");
		const ret22=this.axios_get_prd();
		console.log("ret22",ret22);	
		console.log("E");
		*/


		//----------------------------翻页部分初始化 当前页号page_pages 为指标
		//设置翻页框总显示页数为奇数个
		if(this.page_max%2==0)
		{
			this.page_max=this.page_max+1;
		}
		//设置翻页框当前显示为中间页 刷新生效
		this.page_nums_i=Math.floor(this.page_max/2);
		this.page_pages=this.page_nums_i+1;
		this.page_pages_refresh();

		//设置翻页框当前显示为总显示页数的数组下标0 显示页号为1    下标0~N  显示页号
		this.page_nums_i=0;
		this.page_pages=1;
		this.page_pages_refresh();




	 //上面请求的为异步，不能保证数据的初始化 ，因此一定要初始化 ，但要以请求的数据为准
	 if(!this.show_table_module )
	 {
	 	let str_obj=init_table_module();
	 
	 	this.table_module= JSON.parse(str_obj);
	 	this.show_table_module= JSON.parse(str_obj);
	 	
	 }
	 if(!this.show_data_module) 
	 {
	 
	 	let str_obj=init_data_module();
	 
	 	this.data_module= JSON.parse(str_obj);
	 	this.show_data_module=JSON.parse(str_obj);
	 	
	 	this.show_data_module.val=this.show_data_module.val.slice(0,1);//默认1个
	 	
	 }
	  
	  	 
	 
	 //console.log("A");
	 let that =this;
	 this.axios_get_prdtbmd().then(function (data0) {//第1次获取table模型
	      console.log("axios_get_prdtbmd data0",data0); 
				if(that.deal_getprdtbmd_res(data0,0)=="null")//判断获取结果，未从数据库拿到数据,则初始化一条默认table模型
				{
					let str=JSON.stringify(that.table_module);//存入的是整个table_module
					that.axios_set_prdtbmd(data0,str).then(function (data1)//存入默认的table模型
					{
						console.log("axios_set_prdtbmd data1",data1); 
						if(that.deal_setprdtbmd_res(data1)=="ok")//判断存入结果，ok 存入且清除模型   err 未存入不清除模型
						{
							that.axios_get_prdtbmd().then(function (data2)//第2次获取table模型 
							{
								console.log("axios_get_prdtbmd data2",data2); 
								if(that.deal_getprdtbmd_res(data2,0)=="null")//判断获取结果，未从数据库拿到数据,则初始化一条默认table模型
								{
									
								}
								
							});
						}
						
					});

				}
				else//已从数据库拿到数据
				{
					
				}
				
	    });
			
	 //console.log("B");	 
	
		
		this.axios_get_prd().then(function (data0) {//第1次获取data模型
				console.log("axios_get_prd data0",data0); 
				if(that.deal_getprd_res(data0,0)=="null")//判断获取结果，未从数据库拿到数据,则初始化一条默认data模型
				{
					
					let str=JSON.stringify(that.data_module.val[0]);//存入的是val内的元素
					that.axios_set_prd(data0,str).then(function (data1)//存入默认的data模型
					{
						console.log("axios_set_prd data1",data1); 
						if(that.deal_setprd_res(data1)=="ok")//判断存入结果，ok 存入且清除模型   err 未存入不清除模型
						{
							that.axios_get_prd().then(function (data2)//第2次获取data模型 
							{
								console.log("axios_get_prd data2",data2); 
								if(that.deal_getprd_res(data2,0)=="null")//判断获取结果，未从数据库拿到数据,则初始化一条默认data模型
								{
									
								}
								
							});
						}
						
					});
					
					

				}
				else//已从数据库拿到数据
				{
					
				}
				
			});
			 
	 //console.log("C");	 
	 
	 

	 
	 

		
	},
	beforeMount(){
		
		console.log("beforeMount");		
		

	},
		mounted () {
			
		console.log("mounted");		
		

		
		//console.log("this.table_module=",JSON.stringify(this.table_module) );
		//console.log("this.show_table_module=",JSON.stringify(this.show_table_module) );
		//console.log("this.data_module",JSON.stringify(this.data_module) );
		//console.log("this.show_data_module",JSON.stringify(this.show_data_module));
		
		


		//this.page_data_refresh();
		
		
		this.goto_page(0,1);
	

	
		},
		methods:{
			
		
			async axios_get_prdtbmd(){
				
				//1、发起请求 获取table_module
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				//console.log("usertoken ",usertoken);
				const ret= await axios.post(url_tprd_getprdtbmd,param);
				
				return ret;
				
			},
			deal_getprdtbmd_res(response,val){//val=1  强制初始化
			
				//----------------------------初始化 默认的 table_module 以及 fileter_buf
				if(response.data.res=="ok" && val==0)//以数据库获取的数据优先
				{
					console.log("table_module!=null");
					
					let jsonarry=[];
					
					for(let i=0;i<response.data.list.length;i++)
					{
						jsonarry.push(JSON.parse(response.data.list[i].tableModule));
					}
					
					//理论上表格模型只返回1个,两个变量不能相互赋值，否则会是相同的指针地址，需要先转换（相当于重新开辟空间）
					this.table_module=JSON.parse(JSON.stringify(jsonarry[0]));
					this.show_table_module=JSON.parse(JSON.stringify(jsonarry[0]));
					
					//console.log("this.show_table_module=",JSON.stringify(this.show_table_module) );
					
					return "notnull"
				}
				else /*if(!this.show_table_module )*///为空    不论是否为空都初始化
				{
					console.log("table_module=null");
					
					
					let str_obj=init_table_module();
					
					
					if (str_obj == "" || ( !isJson(str_obj) && !isJsonArray(str_obj) )) 
					{
						alert("str_obj is not JSON or JsonArray");
						return ret;
					}
					
					this.table_module= JSON.parse(str_obj);
					this.show_table_module= JSON.parse(str_obj);
					
					return "null"
				}
				
				return "notnull"
			},
			async axios_set_prdtbmd(response,prdtbmd){
				if(response!=0)
				{
					usertoken=response.data.token;
				}
				
				//1、发起请求 获取table_module
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('prdtbmd',prdtbmd);
				const ret= await axios.post(url_tprd_setprdtbmd,param);
				
				return ret;
			},
			deal_setprdtbmd_res(response){
				
				if(response.data.res=="ok")
				{
					//初始化筛选下拉框 全部不选中
					for(let i=0;i<this.show_table_module.key.length;i++)
					{
						this.fileter_buf[i]=false;
					}

					//首次无，则保存默认。重新清空，再次获取
					//this.show_table_module=null;
					//this.table_module==null;
					
					return "ok";
				}
				
				return "err"
			},
			async axios_get_prd(){
				
				//----------------------------获取数据  初始化默认数据
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('prdcurrent',1);
				param.set('prdsize',this.show_nums);
				const ret= await axios.post(url_tprd_getprd,param);
				return ret;
				
			},
			async axios_get_page_prd(){
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('prdcurrent',this.page_pages);
				param.set('prdsize',this.show_nums);
				
				if(this.time_selected)
				{
					let start_t= new Date(this.query_date_s);
					let end_t= new Date(this.query_date_e);
					start_t=start_t.getTime();//时间戳
					end_t=end_t.getTime();
					
					if(start_t>end_t)
					{
						start_t=this.query_date_e;
						end_t=this.query_date_s;
					}
					else
					{
						start_t=this.query_date_s;
						end_t=this.query_date_e;
					}
					
					param.set('startTime',start_t);
					param.set('endTime',end_t);
					const ret= await axios.post(url_tprd_getprdbytime,param);
					return ret;
				}
				else
				{
					const ret= await axios.post(url_tprd_getprd,param);
					return ret;
				}
				
				
			},
			/*************************************************************************************
			 *  fun     :
			 *  describe:
			 *  input   : val=0  成功更新得到的数据，失败初始化，执行set。 val=1  成功更新得到的数据，失败不初始化，不执行set。
			 *  return  :
			 *************************************************************************************/
			deal_getprd_res(response,val){ 
				//----------------------------初始化默认数据
				if(response.data.res=="ok")
				{
						this.max_nums=response.data.prdtotals;

						console.log("data_module!=null");
						
						//先初始化为json，否则无法读取键
						let str_obj=init_data_module();
						this.data_module= JSON.parse(str_obj);
						this.show_data_module=JSON.parse(str_obj);
						
						this.data_module.val=this.data_module.val.slice(0,0);
						this.show_data_module.val=this.show_data_module.val.slice(0,0);
		
						
						for(let i=0;i<response.data.list.length;i++)
						{
							this.data_module.val[i]=response.data.list[i]; 
							/*this.data_module.val[i]=JSON.parse(response.data.list[i].sflag); 
							
							let objkeys=Object.keys(response.data.list[i]);
							for(let j=0;j<objkeys.length;j++)
							{
								if(objkeys[j]!="sflag")
								{
									this.data_module.val[i][objkeys[j]]=response.data.list[i][objkeys[j]];
								}
							}*/
							
							//1、增加ACT
							this.data_module.val[i]['ACT']='ACT';
							//console.log(Object.keys(response.data.list[i]));
							
							//console.log(JSON.stringify(this.data_module.val[i]) );
						}
						this.show_data_module.val=this.data_module.val.slice(this.show_index_s,this.show_nums);
					

					return "notnull";
					
				}
				else if(val==0)/*if(!this.data_module)*///为空
				{
					console.log("data_module=null");
					
					let str_obj=init_data_module();
					
					if (str_obj == "" || ( !isJson(str_obj) && !isJsonArray(str_obj) ))
					{
						alert("str_obj is not JSON or JsonArray");
						return ret;
					}
					
					this.data_module= JSON.parse(str_obj);
					this.show_data_module=JSON.parse(str_obj);
					
					this.show_data_module.val=this.show_data_module.val.slice(0,1);//默认1个
					
					return "null";
					
				}
				
				return "notnull";//不执行set
			},
			async axios_set_prd(response,prd){
				
				//----------------------------获取数据  初始化默认数据
				usertoken=response.data.token;
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('prd',prd);
				const ret= await axios.post(url_tprd_setprd,param);
				return ret;
			},
			deal_setprd_res(response){
			
				if(response.data.res=="ok")
				{
					//this.data_module=null;
					//this.show_data_module=null;
					
					return "ok";
				}
				
				return "err";
			
			},
			async axios_del_prdota(){
				
				//----------------------------获取数据  初始化默认数据
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('idArray',idArray);
				const ret= await axios.post(url_tprd_deleteprdota,param);
				return ret;
				
			},
			async axios_del_prd(){
				
				//----------------------------获取数据  初始化默认数据
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('idArray',idArray);
				const ret= await axios.post(url_tprd_deleteprd,param);
				return ret;
				
			},
			//============================================================
			setClasstrselect(vals){//btn btn-outline-dark mb-2 btn-lg btn-lg-square
				let obj = {};
				
				obj[`small`] = true;
				
				if(vals)	  obj[`text-danger  border-danger xbg-danger`] = true;
				
				return obj;
			},
			//============================================================【时间查询】 需要到数据库查询
			date_query(evt){
				
				
				
				if(this.query_date_s && this.query_date_e)
				{
					
					this.time_selected=true;

					
					let start_t= new Date(this.query_date_s);
					let end_t= new Date(this.query_date_e);
					start_t=start_t.getTime();//时间戳
					end_t=end_t.getTime();
					
					
					if(start_t>end_t)
					{
						start_t=this.query_date_e;
						end_t=this.query_date_s;
					}
					else
					{
						start_t=this.query_date_s;
						end_t=this.query_date_e;
					}
					

					
					let that=this;
					this.axios_get_page_prd().then(function (data) {//第1次获取data模型
							console.log("axios_get_page_prd date_query",data); 
	
							that.deal_getprd_res(data,1);
							
							that.page_pages=1;//时间查询回到第一页
							that.page_nums_i=0;
							that.page_pages_refresh();
							
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
							
					});

				
				}
				else//无时间 直接赋值默认+显示条数
				{

					this.time_selected=false;
					
					let that=this;
					this.axios_get_page_prd().then(function (data) {//第1次获取data模型
							console.log("axios_get_page_prd NOT date_query",data); 
						
							that.deal_getprd_res(data,1);
							
							that.page_pages=1;//时间查询回到第一页
							that.page_nums_i=0;
							that.page_pages_refresh();
							
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
							
					});
					
				}
				
				
			},
			del_select_click(evt,val){//同步选中的变化
				
				
				if(val==1 || evt.target.id=="th_tr_inpall")
				{
					//alert(this.show_data_module.val.length);
					
					
					
					if(this.del_select_all)//true 全选
					{
						for(let i=0;i<this.show_data_module.val.length;i++)
						{
							this.del_select_buf[i]=true;
						}
			
					}
					else
					{
						
						for(let i=0;i<this.show_data_module.val.length;i++)
						{
							this.del_select_buf[i]=false;
						}
						
					}
					
					
			
				}
				else
				{
					//alert(evt.target.id);
			
					if(evt.target.id=="th_tr_inpall")
					{
						
					}
					else
					{
						let idstr = evt.target.id.slice(12,evt.target.id.length);
						
					}
					
					
				}
				
				
				
				let jsonstr={};
				let str="";
				for(let i=0;i<this.show_data_module.val.length;i++)
				{
					if(this.del_select_buf[i]==true && this.show_data_module.val[i].flag==0)
					{
						jsonstr.K=this.show_data_module.val[i].prdid;
						
						if(str!="")str+=","
						str+=JSON.stringify(jsonstr)
					}
				}
				str='{"id":['+str+']}';
				//alert(str);
				
				idArray=str;
				
				
				
			},
			//============================================================【筛选】
			filter_click(evt){
				
				if(evt.target.id=="filter_inp_all")
				{
					
					//alert(this.fileter_all);
					
					if(this.fileter_all)//true 不显示
					{
						for(let i=0;i<this.show_table_module.key.length;i++)
						{
							this.fileter_buf[i]=true;
							delete this.show_table_module.key[i].K;
						}
					}
					else
					{
						
						for(let i=0;i<this.show_table_module.key.length;i++)
						{
							this.fileter_buf[i]=false;
						}
						
						let sss=JSON.stringify(this.table_module);
						this.show_table_module= JSON.parse(sss);
						
					}
					
					
					return ;
				}
				
				
				
				let idstr =parseInt(evt.target.id.slice(10,evt.target.id.length)) ;
				
				
				if(this.fileter_buf[idstr])//true 不显示
				{
					delete this.show_table_module.key[idstr].K;
					//alert("un_show="+idstr);
				}
				else
				{
					//alert("show="+idstr);
					
					let sss=JSON.stringify(this.table_module);
					this.show_table_module= JSON.parse(sss);
					
					//this.show_table_module=this.table_module;//直接赋值会导致改变一个，两个同时改变
					
					//alert(JSON.stringify(this.table_module));
					
					for(let i=0;i<this.show_table_module.key.length;i++)
					{
						if(this.fileter_buf[i])//true 不显示
						{
							delete this.show_table_module.key[i].K;
							
						}
					}
					
					//alert(JSON.stringify(this.table_module));
					
				}
				
				
			},
		  //============================================================【创建产品】
		  create_product(evt){
				//------------------------------------------------------获取对应模态框下元素
				//alert(evt.target.id);//mdok_4
				let idstr = evt.target.id.slice(5,evt.target.id.length);
				//alert(parseInt(idstr));
				let a = document.getElementById("myModal" + parseInt(idstr));
				//alert(a);
				let selectval = a.getElementsByTagName("select");
				let inputval = a.getElementsByTagName("input");
				//alert(inputval.length);
				//------------------------------------------------------创建数组
				let typestr = new Array();
				//------------------------------------------------------获取下拉框的值
				if(selectval.length>0)	typestr[0]=selectval[0].value;
				if(selectval.length>1)	typestr[1]=selectval[1].value;
				//------------------------------------------------------获取文本框的值
				for (let i = 0; i < inputval.length; i++) //00~99
				{
					typestr[i+2] = inputval[i].value;
				}
				//----------------------------------------------------判断指定的必填值是否为空，必填为空直接退出 stp1
				for (let i = 0; i < typestr.length; i++) //只判断 必填项
				{
	
					if (typestr[i] == "" && i ==2 ) //3代表线数文本框
					{
							alert("数组(包含下拉框)第" + i + "个下标为空，请填写");
							return;
					}
				}
				//----------------------------------------------------
				//console.log("typestr=", typestr);
				
				let prd={};
				
				prd.protocos=typestr[0];
				prd.type=typestr[1];
				prd.prdname=typestr[2];
				
				let param = new URLSearchParams();
				param.set('username',username);
				param.set('usertoken',usertoken);
				param.set('prd',JSON.stringify(prd));
				axios.post(url_tprd_createprd,param).then(response => {
					
					console.log(response.data);
					
					if(response.data.res=="ok")
					{
						usertoken=response.data.token;
						
						//1、查询产品
						let that=this;
						this.axios_get_prd().then(function (data) {
							if(data.data.res=="ok"){
								that.deal_getprd_res(data,1);
							}
							
						});
						
					}
					
					
				}).catch((err) => {
					console.log(err);
				});
				

			},
			//============================================================【修改表格文档】
			ck_md_textarea(evt) {
			
				
				let ereaid = "md1_textarea";
				let json_data;
				json_data = document.getElementById(ereaid).value;
				
				if (json_data == "" || ( !isJson(json_data) && !isJsonArray(json_data) )) return;
				
				this.show_table_module= JSON.parse(json_data);
				
				this.jsonData = JSON.parse(json_data);
				
			},
			add_keyall(evt){
				let a = document.getElementById("tball_inp");
				
				let str_obj = a.value;
				
				if (str_obj == "" || ( !isJson(str_obj) && !isJsonArray(str_obj) ))
				{
					alert("tball_inp is not JSON or JsonArray");
					return;
				}
				
				this.show_table_module=JSON.parse(a.value);
				this.table_module=JSON.parse(a.value);
				
				let that =this;
				let str=JSON.stringify(that.table_module);//存入的是整个table_module
				that.axios_set_prdtbmd(0,str).then(function (data1)//存入默认的table模型
				{
					console.log("axios_set_prdtbmd save_module",data1); 
					if(that.deal_setprdtbmd_res(data1)=="ok")//判断存入结果，ok 存入且清除模型   err 未存入不清除模型
					{
						that.axios_get_prdtbmd().then(function (data2)//第2次获取table模型 
						{
							console.log("axios_get_prdtbmd save_module",data2); 
							that.deal_getprdtbmd_res(data2,0);//判断获取结果，未从数据库拿到数据,则初始化一条默认table模型
							
							
						});
					}
					
				});
				
				
				
				alert("全写成功");
			},
			add_key(evt){
				
				//alert("add_key");	
				//alert(this.show_table_module.key.length);	
				this.show_table_module.key[this.show_table_module.key.length]=JSON.parse('{"K":"K'+this.show_table_module.key.length+'"}'); 
				//alert(JSON.stringify(this.show_table_module.key));	
				//alert(this.show_table_module.key[this.show_table_module.key.length-1].K);	
				this.show_table_module.name[0][this.show_table_module.key[this.show_table_module.key.length-1].K]=this.show_table_module.key[this.show_table_module.key.length-1].K+'_blank';
			
			
				alert("添加成功");
			},
			del_key(evt){
				
				//alert(this.show_table_module.key[this.show_table_module.key.length-1].K);
				//alert(this.show_table_module.name[0][this.show_table_module.key[this.show_table_module.key.length-1].K]);
				
				delete this.show_table_module.name[0][this.show_table_module.key[this.show_table_module.key.length-1].K];
				this.show_table_module.key.pop();
				
				alert("删除成功");
			},
			save_module(evt){
				let a,b;
				let inputvala,inputvalb;
				
				a = document.getElementById("tbname");
				inputvala = a.getElementsByTagName("input");
				this.show_table_module.tbmdname.name=inputvala[0].value;
				
				a = document.getElementById("tbkey");
				inputvala = a.getElementsByTagName("input");
				
				b = document.getElementById("tbmean");
				inputvalb = b.getElementsByTagName("input");
				
				for (let i = 0; i < inputvala.length; i++)
				{
					
						if(inputvala[i].value!="")
						{
							this.show_table_module.key[i].K=inputvala[i].value;
							this.show_table_module.name[0][this.show_table_module.key[i].K]=inputvalb[i].value;
						}
						else
						{
							delete this.show_table_module.key[i].K;
						}
	
				}

				let sss=JSON.stringify(this.show_table_module);
				this.table_module= JSON.parse(sss);
				
				
				let that =this;
				let str=JSON.stringify(that.table_module);//存入的是整个table_module
				that.axios_set_prdtbmd(0,str).then(function (data1)//存入默认的table模型
				{
					console.log("axios_set_prdtbmd save_module",data1); 
					if(that.deal_setprdtbmd_res(data1)=="ok")//判断存入结果，ok 存入且清除模型   err 未存入不清除模型
					{
						that.axios_get_prdtbmd().then(function (data2)//第2次获取table模型 
						{
							console.log("axios_get_prdtbmd save_module",data2); 
							that.deal_getprdtbmd_res(data2,0);//判断获取结果，未从数据库拿到数据,则初始化一条默认table模型
							
							
						});
					}
					
				});
				
				alert("保存成功");
			},
			reset_module(evt){
				
				let str_obj="";
				
				str_obj=init_table_module();
				
				if (str_obj == "" || ( !isJson(str_obj) && !isJsonArray(str_obj) )) 
				{
					alert("str_obj is not JSON or JsonArray");
					return;
				}
				
				this.show_table_module= JSON.parse(str_obj);

				alert("重置成功");
			},
			//============================================================
			detail_click(evt){
				let idstr = evt.target.id.slice(16,evt.target.id.length);//tbody_tr_detail_
				
				this.detailid=parseInt(idstr);
				
				//alert(idstr);
			},
			dev_click(evt){
				let idstr = evt.target.id.slice(16,evt.target.id.length);//tbody_tr_detail_
				//alert(idstr);
				//this.$router.push('/home/device');
				let that=this;
				this.$router.push({name:'device',params:
				{
					username:username,
					usertoken:usertoken,
					type:"product",
					typename:that.show_data_module.val[idstr].prdname,
					typeid:that.show_data_module.val[idstr].prdid,
					protocos:that.show_data_module.val[idstr].protocos
				}});
				
			},
			ota_click(evt){
				let idstr = evt.target.id.slice(16,evt.target.id.length);//tbody_tr_detail_
				
				idstr=parseInt(idstr);
				
				//this.$router.push('/home/otaupload');
				//alert(this.show_data_module.val[idstr].prdid)
				
				let that=this;
				this.$router.push({name:'otaupload',params:
				{
					username:username,
					usertoken:usertoken,
					type:"product",
					typename:that.show_data_module.val[idstr].prdname,
					typeid:that.show_data_module.val[idstr].prdid,
					entity:that.show_data_module.val[idstr].otahttpuri,
					uimodule:"null"
				}});


			},
			sent_click(evt){
				let idstr = evt.target.id.slice(16,evt.target.id.length);//tbody_tr_detail_
				//alert(idstr);
				
				this.$router.push('/home/sent_ui');
			},
			del_click(evt){
				let idstr = evt.target.id.slice(16,evt.target.id.length);//tbody_tr_detail_
				
				//alert(idstr);
				//alert(this.show_data_module.val[idstr].prdid);
				
				let jsonstr={};
				let str="";
				
				jsonstr.K=this.show_data_module.val[idstr].prdid;
				str=JSON.stringify(jsonstr)
				if(this.show_data_module.val[idstr].flag==0)
				{
					str='{"id":['+str+']}';
				}
				else
				{
					str='{"id":['+20+']}';
				}
				
			
				idArray=str;
				
				let that=this;
				
				this.axios_del_prdota().then(function (data) {
					console.log("axios_del_prdota del_click",data);
					
					if(data.data.res=="ok")
					{
						that.axios_del_prd().then(function (data0) {
							console.log("axios_del_prd del_click",data0);
							
							if(data0.data.res=="ok")
							{
								that.del_select_all=false;
								that.del_select_click(0,1);
								
								that.goto_page(0,1);
							}
							
						});
					}
					
				});
				
				
				
			},
			del_selected(evt){
				
				let that=this;
				
				this.axios_del_prdota().then(function (data) {
					console.log("axios_del_prdota del_click",data);
					
					if(data.data.res=="ok")
					{
						that.axios_del_prd().then(function (data0) {
							console.log("axios_del_prd del_click",data0);
							
							if(data0.data.res=="ok")
							{
								that.del_select_all=false;
								that.del_select_click(0,1);
								
								that.goto_page(0,1);
							}
							
						});
					}
					
				});
				
				
			},
		  //============================================================
		  chage_tb_show_nums(){
				//alert(this.show_nums);

				/*that.page_pages=1;//时间查询回到第一页
				that.page_nums_i=0;
				that.page_pages_refresh();*/
				
				let that=this;
				this.axios_get_page_prd().then(function (data) {//第1次获取data模型
						console.log("axios_get_page_prd chage_tb_show_nums-1",data);
						
						if(data.data.res=="ok")
						{
							that.deal_getprd_res(data,1);
							that.show_data_module=JSON.parse(JSON.stringify(that.data_module));
							that.del_select_click(0,1);
						}
						else
						{
							that.page_pages=1;//时间查询回到第一页
							that.page_nums_i=0;
							that.page_pages_refresh();
							
							that.axios_get_page_prd().then(function (data1) {//第1次获取data模型
									console.log("axios_get_page_prd chage_tb_show_nums-2",data1);
									if(data1.data.res=="ok")
									{
										that.deal_getprd_res(data1,1);
										that.show_data_module=JSON.parse(JSON.stringify(that.data_module));
										that.del_select_click(0,1);
									}
							});
						}
						
						
				});

				
				
			},
			page_pages_refresh()
			{
				
				
				let page_mid=0;
				
				if(this.page_pages<=this.page_max)
				{
					this.page_nums_i=this.page_pages-1;
					
					for(let i=0;i<this.page_max;i++)
					{
						this.page_nums[i]=i+1;
					}
				}
				else
				{
					
					
					let pages_mid=0;
				
					
					page_mid=Math.floor(this.page_max/2);//选中中间
					//this.page_nums_i=page_mid;
					
					this.page_nums[page_mid]=this.page_pages;
					
					let j=0;
					for(let i=page_mid;i>0;i--)//左 2个
					{
						this.page_nums[j]=this.page_pages-i;
						j++;
					}
					
					j=0;
					for(let i=(page_mid+1);i<this.page_max;i++)//右两个
					{
						j++;
						this.page_nums[i]=this.page_pages+j;
					}
					
				}
				
				//alert(this.page_pages);
				//alert(this.page_nums);
				//alert(this.page_checked);
				
				for(let i=0;i<this.page_max;i++)
				{
					
					if(this.page_pages==this.page_nums[i])
					{
						this.page_checked[i]=true;
					}
					else
					{
						this.page_checked[i]=false;
					}
					
				}
				
				
			 /*
				let pages_index=0;
				let pages_mid=0;
				let pages_num_mid=0;
		
			 //中间的下标+1
			 pages_mid=Math.floor(this.page_max/2)+1;//5 7中间的标号1 2 3 4 5   即3      1234567  4
			 pages_num_mid=this.page_pages%pages_mid;
	
			 for(let i=0;i<this.page_max;i++)
			 {
					this.page_checked[i]=false;//所有的初始为不选中
					this.page_checked[this.page_nums_i]=true;
					
					//if(pages_num_mid==0 && this.page_pages>(pages_mid-1))
					//{
					//	this.goto_page(0,1);
					//	pages_num_mid=1;
					//}
					
			 }
			 */
			 
			},
			page_pages_del(){
				
				if(this.page_pages>1)	
				{
					this.page_pages--;
					
					/*this.page_nums_i--;
					if(this.page_nums_i<0)  
					{
						this.page_nums_i=Math.floor(this.page_max/2);
					}*/
					
					//this.page_pages_refresh();
					this.goto_page(0,1);
				}
				
				
				
				/*let that=this;
				this.axios_get_page_prd().then(function (data) {//第1次获取data模型
						console.log("axios_get_page_prd page_pages_del",data);
						
						if(data.data.res=="ok")
						{
							that.deal_getprd_res(data,1);
							
							that.page_pages_refresh();
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
						}
						
				});*/
				
				
				
				
				
			},
			page_pages_add(){
				
				
				/*if(this.page_pages<100)*/	this.page_pages++;
				/*this.page_nums_i++;
				if(this.page_nums_i>=this.page_max)  this.page_nums_i=Math.floor(this.page_max/2);*/
				//this.page_pages_refresh();
				this.goto_page(0,1);			
				/*let that=this;
				this.axios_get_page_prd().then(function (data) {//第1次获取data模型
						console.log("axios_get_page_prd page_pages_add",data);
						
						if(data.data.res=="ok")
						{
							
							that.deal_getprd_res(data,1);
							
							that.page_pages_refresh();
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
						}
				});*/
				
				
			},
			page_pages_click(evt){//btnradio_14
				
				let idstr = evt.target.id.slice(9,evt.target.id.length);//tbody_tr_detail_
				//alert(idstr);
				/*this.page_nums_i=parseInt(idstr);*/
				this.page_pages=this.page_nums[parseInt(idstr)];
				
				//alert(this.page_nums_i);
				//alert(this.page_pages);
				
				//this.page_pages_refresh();
				this.goto_page(0,1);
				
				/*let that=this;
				this.axios_get_page_prd().then(function (data) {//第1次获取data模型
						console.log("axios_get_page_prd page_pages_click",data);
						if(data.data.res=="ok")
						{
							that.deal_getprd_res(data,1);
							
							that.page_pages_refresh();
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
						}
						
				});*/
				
				
			},
		  //============================================================
		  goto_page(evt,cmd){
				
				if(cmd==0)//跳转指定页
				{
					let vals=parseInt(document.getElementById("goto_page_inp").value);
					if(vals>0)	this.page_pages=vals;
				}
				
				
				/*let page_mid=0;
				
				if(this.page_pages<=this.page_max)
				{
					this.page_nums_i=this.page_pages-1;
					
					for(let i=0;i<this.page_max;i++)
					{
						this.page_nums[i]=i+1;
						
						this.page_checked[i]=false;
						this.page_checked[this.page_nums_i]=true;
					}
				}
				else
				{
					
					page_mid=Math.floor(this.page_max/2);//2
					this.page_nums_i=page_mid;
					
					this.page_nums[page_mid]=this.page_pages;
					
					let j=0;
					for(let i=page_mid;i>0;i--)//左 2 1
					{
						this.page_nums[j]=this.page_pages-i;
						j++;
					}
					
					j=0;
					for(let i=(page_mid+1);i<this.page_max;i++)//右
					{
						j++;
						this.page_nums[i]=this.page_pages+j;
					}
				}*/

				//此处需要判断到头，查询数据库
				//this.page_data_refresh();
				let that=this;
				this.axios_get_page_prd().then(function (data) {//第1次获取data模型
						console.log("axios_get_page_prd goto_page",data);
						if(data.data.res=="ok")
						{
							that.deal_getprd_res(data,1);
							
							that.page_pages_refresh();
							//此处需要判断到头，查询数据库
							that.page_data_refresh();
						}
						else
						{
							if(that.page_pages>1)	
							{
								that.page_pages--;
								that.goto_page(0,1);
							}
							
						}
						
				});

				
			},
		  //============================================================
		  page_data_refresh(){
				
				//let total_num=this.data_module.val.length;
				//alert(total_num);
				
				/*if((this.page_pages*this.show_nums)>total_num)
				{
					//查询范围超出本地存储的数据，需要重新查询数据库 带上 show_index_s	 page_max*200
					//alert("查询数据库");
					
				}
				else
				{
					this.show_index_s=(this.page_pages-1)*this.show_nums;
				
					let json_a={};
					json_a=JSON.parse(JSON.stringify(this.data_module));
					this.show_data_module.val=json_a.val.slice(this.show_index_s,this.show_index_s+this.show_nums);
					
				}*/
				
				this.show_index_s=(this.page_pages-1)*this.show_nums;
								
				let json_a={};
				json_a=JSON.parse(JSON.stringify(this.data_module));
				this.show_data_module.val=json_a.val;
				
				
			},
		  //============================================================
		  next_page(evt){},
		  //============================================================
		  tbodyclick(evt){},
		  //============================================================
		  tbtnclick(evt){},
		  
		  
		  
		}
		  
}




//*************************************************************************************【页面JS全局函数】
//*************************************************************************************【页面JS全局函数】
//*************************************************************************************【页面JS全局函数】
//*************************************************************************************【页面JS全局函数】
//*************************************************************************************【页面JS全局函数】
//*************************************************************************************【页面JS全局函数】


/*************************************************************************************
*
*
*
*alert(stringjson.sites[0].productname); alert(stringjson.sites.length);
*************************************************************************************/
/*
var get_show_table_module = function (tbmd){
	
	let bufa=[];
	let bufb=[];
	let json_obj={}; 
	let json_last={};
	
	json_obj.name=tbmd.prdtable[0].name;
	bufb.push(json_obj);
	json_last.tbmdname=bufb;
		
	
	bufb=[];
	for (let i = 0; i < tbmd.key.length;i++)
	{
		json_obj={};
		//alert("tbmd.key[i].K="+tbmd.key[i].K);
		json_obj.K=tbmd.key[i].K;
		bufb.push(json_obj);
	}
	json_last.key=bufb;
	
	bufb=[];
	json_obj={};
	for (let i = 0; i < tbmd.key.length;i++)
	{
		//alert("tbmd.key[i].K="+tbmd.key[i].K);
		if(tbmd.name[0][tbmd.key[i].K])
		{
			json_obj[tbmd.key[i].K]=tbmd.name[0][tbmd.key[i].K];
		}
	}
	bufb.push(json_obj);
	
	json_last.name=bufb;
	
	return json_last;
	
}
*/
/*************************************************************************************
*
*
*
*
*************************************************************************************/
var init_table_module = function  (){
	
	let str_obj="";
	
	str_obj = '"key":[{"K":"prdname"},{"K":"protocos"},{"K":"flag"},{"K":"sflag"},{"K":"createTime"},{"K":"otahttpuri"},{"K":"ACT"}],';
	str_obj+='"tbmdname": [{"name":"TBMD-0"}],"name": [{"prdname":"产品名称","protocos":"产品协议","flag":"设备个数","sflag":"备注","createTime":"创建时间","otahttpuri":"OTA文件","ACT":"产品操作"}]'
				
	str_obj = '{' + str_obj + '}'; 
	
	if (str_obj == "" || ( !isJson(str_obj) && !isJsonArray(str_obj) )) 
	{
		alert("str_obj is not JSON or JsonArray");
		return null;
	}
	
	return str_obj;
}
/*************************************************************************************
 *  author  :
 *  date    :
 *  fun     :
 *  describe:
 *  input   :
 *  output  :
 *************************************************************************************/
var init_data_module = function  (){
	
	let json_str="";
	
	
	for(let i=0;i<100;i++)
	{
		let num= Math.floor(Math.random()*1000);
		let m= Math.floor(Math.random()*60);
		let s= Math.floor(Math.random()*60);
		//console.log(num);
		
		if(i>0)	json_str+=",";
		json_str+='{"NAME":"默认产品'+i+'","PRO":"MQTT","DEVS":998,"TIME":"2023-08-29 15:'+m+':'+s+'.'+num+'","OTA":"sgdiot1","ACT":"ACT","K6":"sdfsdgsgsd","sflag":"set 创建","K8":"access_key","K9":"APIkey"}';
	}
	
	let str_obj='{"val":['+json_str+']}';
	
 
 
	/*
	let str_obj='{"val":['+
		'{"K0":"洗衣机0","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:57:27.755","OTA":"sgdiot1","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"},'+
		'{"K0":"洗衣机1","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:51:21.155","OTA":"sgdiot2","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"},'+
		'{"K0":"洗衣机2","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:55:25.555","OTA":"sgdiot3","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"},'+
		'{"K0":"洗衣机3","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:53:23.355","OTA":"sgdiot4","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"},'+
		'{"K0":"洗衣机4","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:52:22.255","OTA":"sgdiot5","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"},'+
		'{"K0":"洗衣机5","K1":"MQTT","K2":998,"TIME":"2023-08-29 15:54:24.435","OTA":"sgdiot6","ACT":"ACT","K6":"sdfsdgsgsd","K7":"MQTT","K8":"gryujiik1235=fgfh","K9":"adasdasfasfr55863"}'+
		']}';*/
			
	return str_obj;
}
/*************************************************************************************
*
*
*
*
*************************************************************************************/
function reload_table_page(){}



/*************************************************************************************
*
*
*
*
*************************************************************************************/

function delet_pdt(){
	
}


/*************************************************************************************
*
*
*
*
*************************************************************************************/
function gotodevice(productid){


	this.$router.push({name:'device',params:
	{
				username:username,
				usertoken:usertoken,
				productid:productid
	}});	

}




</script>






<style>
	
	
	button,
	input,
	select,
	textarea{
		border: 1px solid #000000;
		border-radius: 5px 5px 5px 5px;
	}
	
	
	
	::placeholder{
		color: #00ff00;
		text-align: center;
	}
	
	
	
</style> 

	