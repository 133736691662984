<template>

			

				<!-- 路由视图 v-if="isRouterAlive"-->
				<router-view ></router-view>
  
			
			
</template>


<script>
	
	
import * as echarts from 'echarts'	
import { ref, nextTick, provide } from "vue";



export default { 
  name: "App",

  data () {
    return {
      msgapp: 'msgapp'
    }
  },
	setup(){
			
	    provide('ec',echarts)//provide
			
	  },
	  components: {
	  },
	mounted () {
		

	  
	},

};

</script>



