import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import JsonViewer from 'vue-json-viewer'

//import jsonp from 'jsonp';
//import $ from 'jquery'


// -------------------------------------------------------------样式表
import 'jquery' 
import "bootstrap"


import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";

import "./assets/css/sgdstyle.css";

import './assets/css/font-awesome.min.css';

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader




// -------------------------------------------------------------自定义组件 引入
import CardSlider from './components/CardSlider.vue';
import HelloRunoob from './components/HelloRunoob.vue';
import Myverify from './components/Myverify.vue';
// -------------------------------------------------------------Vuetify
import 'vuetify/styles'//可以在页面局部引入
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
});

// -------------------------------------------------------------
//import $ from 'jquery';	


// -------------------------------------------------------------百度地图
import BaiduMap from 'vue-baidu-map-3x'

import {BmlMarkerClusterer} from 'vue-baidu-map-3x'


// -------------------------------------------------------------echarts
/*
import * as echarts from 'echarts/core';
import { TooltipComponent, VisualMapComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { Scatter3DChart } from 'echarts-gl/charts';
import { Grid3DComponent } from 'echarts-gl/components';

echarts.use([
  TooltipComponent,
  VisualMapComponent,
  Grid3DComponent,
  Scatter3DChart,
  CanvasRenderer
]);
*/





const app = createApp(App);


// -------------------------------------------------------------自定义组件挂载
app.component('bm-marker-clusterer', BmlMarkerClusterer);
app.component('CardSlider', CardSlider);
app.component('HelloRunoob', HelloRunoob);


app.use(BaiduMap,{ak:'j71SNXHu651iHhbDVY4G5GQ5x9hv6GvL',});


app.use(JsonViewer).use(store).use(router).use(vuetify).mount('#app');






