<template>
	
  <div class="s-canvas">
    <canvas id="s-canvas"  :width="contentWidth" :height="contentHeight" @click="changeNum"></canvas>
  </div>
	
	<!--
	<h1>{{value}}</h1>
	-->
	
	
</template>


<script >
	
	
	import $ from 'jquery';	
	import * as Math from 'mathjs'
	


	
export default {
  name: 'Myverify',
	props: {
	  dictKey: String, // 字典code
	  updataDict: String, // 回显绑定的值
	  value:{type: String,default: "100"},
		// 带有默认值的对象
		propE: {
			type: Object,
			// 对象或数组默认值必须从一个工厂函数获取
			default: function () {
				return { message: 'hello' }
			}
		}
	},
  data(){
        return{
            identifyCode:'',
            fontSizeMin:25,
            fontSizeMax:35,
            backgroundColorMin:200,
            backgroundColorMax:200,
            dotColorMin:120,
            dotColorMin:60,
            contentWidth:116,
            contentHeight:38
        };
  },
	
	watch: {
	  dictKey: {
	    immediate: true,
	    handler() {
	      
	    }
	  },
	  value: {
	    handler(val) {
				/*alert("val="+val);*/
	      this.changeNum();
	    },
	    deep: true
	  }
	},
	mounted() {
		
			//alert("this.value="+ this.value);
			
	    this.changeNum();
			
			//alert(this.identifyCode);
			//alert(typeof(this.value) );
			//alert(typeof(this.identifyCode) );
			
			//this.value="this.identifyCode";
			
			//alert("this.value="+ this.value);
			
	},
  methods: {
		pic_change(val){
			
			this.$emit('picchange',val);
			
		},
    changeNum(){
        let randomNum=(this.randomNum(1000,9999));
        this.identifyCode=randomNum.toString();
        this.$emit('emitCode',this.identifyCode);
         this.drawPic();
				 
				 this.pic_change(this.identifyCode);
    },
    // 生成一个随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 生成一个随机的颜色
    randomColor(min, max) {
      let r = this.randomNum(min, max)
      let g = this.randomNum(min, max)
      let b = this.randomNum(min, max)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    drawPic() {
      let canvas = document.getElementById('s-canvas')
      let ctx = canvas.getContext('2d')
      ctx.textBaseline = 'bottom'
      // 绘制背景
      ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax)
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
      // 绘制文字
      for (let i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i)
      }
      this.drawLine(ctx)
      this.drawDot(ctx)
    },
    drawText(ctx, txt, i) {
      ctx.fillStyle = this.randomColor(50, 160) //随机生成字体颜色
      ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei' //随机生成字体大小
      let x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
      let y = this.randomNum(this.fontSizeMax, this.contentHeight - 5)
      var deg = this.randomNum(-30, 30)
      // 修改坐标原点和旋转角度
      ctx.translate(x, y)
      ctx.rotate(deg * Math.pi / 180)
      ctx.fillText(txt, 0, 0)
      // 恢复坐标原点和旋转角度
      ctx.rotate(-deg * Math.pi / 180)
      ctx.translate(-x, -y)
    },
    drawLine(ctx) {
      // 绘制干扰线
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(100, 200)
        ctx.beginPath()
        ctx.moveTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
        ctx.lineTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
        ctx.stroke()
      }
    },
    drawDot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 30; i++) {
        ctx.fillStyle = this.randomColor(0, 255)
        ctx.beginPath()
        ctx.arc(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight), 1, 0, 2 * Math.pi)
        ctx.fill()
      }
    }
  }

}


</script >




<style>
</style>