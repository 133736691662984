import { createRouter, createWebHashHistory } from 'vue-router'

import product from '../views/product.vue'



const routes = [
  {
    path: '/',
    name: 'sigin',
    //component: sigin
	component: () => import('../views/signin')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/signup')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/signin')
  },
	{
		path: '/changepass',
		name: 'changepass',
		component: () => import('../views/changepass')
	},
  {
		path: '/home',
		name: 'home',
		component: () => import('../views/HomeView'),
		children: [
				  {
						path: 'product',
						name: 'product',
						component: product
				  },
				  {
						path: 'device',
						name: 'device',
						component: () => import('../views/device.vue')
				  },
				  {
						path: 'devicebind',
						name: 'devicebind',
						component: () => import('../views/devicebind.vue')
				  },
				  {
						path: 'devicestatus',
						name: 'devicestatus',
						component: () => import('../views/devicestatus.vue')
				  },
				  {
						path: 'otaupload',
						name: 'otaupload',
						component: () => import('../views/otaupload.vue')
				  },
					{
						path: 'line_race',
						name: 'line_race',
						component: () => import('../views/line_race.vue')
					},
					{
						path: 'mapbaidu',
						name: 'mapbaidu',
						component: () => import('../views/mapbaidu.vue')
					}
					,
					{
						path: 'sent_ui',
						name: 'sent_ui',
						component: () => import('../views/sent_ui.vue')
					}
					
		]
  }
 
	  
	 

  
]






const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
